.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;

  &.backdrop-shaded {
    background: rgba(0,0,0,.5);
    backdrop-filter: blur(5px);
  }

  &.backdrop-black {
    background: #000;
  }
}
