.image-bevel {
  @apply relative overflow-hidden rounded;

  &::after {
    @apply absolute inset-0 flex w-full h-full items-center justify-center border border-black rounded;

    content: '';
    box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.75);
  }
}
