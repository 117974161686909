.heading-label {
  -webkit-text-stroke: 0;
}

.heading-label::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: 25%;
  background-color: theme('colors.blue.500');
  border-radius: 2px;
  transform: translate(-50%, -50%);
}
