.modal-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 999;
  transition: height 0s ease-out 1s;

  &.active {
    height: 100%;
    transition: height 0s ease-in 0s;
    .backdrop {
      visibility: visible;
      opacity: 1;
    }
  }

  .backdrop {
    visibility: hidden;
    opacity: 0;
    transition: all .6s ease-in-out;
  }
}

.modal-panel {
  position: relative;
  max-height: 90vh;
  z-index: 999;
  overflow: auto;
  opacity: 0;
  transform: scale(0.8);
  transition: all .3s ease-in-out;

  &.active {
    opacity: 1;
    transform: none;
  }

  img, svg {
    display: block;
    max-height: 80vh;
    width: auto;
  }

  &-cover {
    display: block;
    width: 100%;
    top: 0;
    height: 100%;
    max-height: 100%;
    margin: 0;
    overflow: auto;
  }
}
