.shadow-black {
  box-shadow: 0px 0px 0px 1px theme('colors.black');
}

.shadow-inset-black {
  box-shadow: inset 0px 0px 0px 1px theme('colors.black');
}

.shadow-white {
  box-shadow: 0px 0px 0px 1px theme('colors.white');
}

.shadow-inset-white {
  box-shadow: inset 0px 0px 0px 1px theme('colors.white');
}

.shadow-inset-lighten-1 {
  box-shadow: inset 0px 0px 0px 1px rgba(255,255,255,0.15);
}

.shadow-inset-lighten-100 {
  box-shadow: inset 0px 0px 0px 1px theme('colors.lighten.100');
}

.shadow-inset-lighten-200 {
  box-shadow: inset 0px 0px 0px 1px theme('colors.lighten.200');
}

.shadow-blue-100 {
  box-shadow: 0px 0px 0px 1px theme('colors.blue.100');
}

.shadow-tab-blue-100 {
  box-shadow: -1px -1px 0px 0px theme('colors.blue.100'),
               1px -1px 0px 0px theme('colors.blue.100');
}

.shadow-inset-blue-100 {
  box-shadow: inset 0px 0px 0px 1px theme('colors.blue.100');
}

.shadow-inset-blue-200 {
  box-shadow: inset 0px 0px 0px 1px theme('colors.blue.200');
}

.shadow-blue-900 {
  box-shadow: 0px 0px 0px 1px theme('colors.blue.900');
}

.shadow-inset-blue-900 {
  box-shadow: inset 0px 0px 0px 1px theme('colors.blue.900');
}

.shadow-blue-box {
  box-shadow: -1px 1px 0px 1px theme('colors.teal.300'),
              1px -1px 0px 1px theme('colors.blue.600');
}

.shadow-inset-blue-box {
  box-shadow: inset -1px  1px 0px 1px theme('colors.teal.300'),
              inset  1px -1px 0px 1px theme('colors.blue.600');
}

.shadow-inset-thin-blue-box {
  box-shadow: inset -1px  1px 0px 0px theme('colors.teal.300'),
              inset  1px -1px 0px 0px theme('colors.blue.600');
}

.shadow-yellow-box {
  box-shadow: -1px  1px 0px 1px theme('colors.yellow.100'),
               1px -1px 0px 1px theme('colors.yellow.600');
}

.shadow-inset-yellow-box {
  box-shadow: inset -1px  1px 0px 1px theme('colors.yellow.100'),
              inset  1px -1px 0px 1px theme('colors.yellow.600');
}

.shadow-brown-box {
  box-shadow: -1px 1px 0px 1px theme('colors.brown.200'),
              1px -1px 0px 1px theme('colors.brown.900');
}

.shadow-inset-brown-box {
  box-shadow: inset -1px  1px 0px 1px theme('colors.brown.200'),
              inset  1px -1px 0px 1px theme('colors.brown.900');
}
