[x-cloak] {
  display: none;
}

html {
  cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgAgMAAAAOFJJnAAAACVBMVEUAAAAAAAD///+D3c/SAAAAAXRSTlMAQObYZgAAAE5JREFUeAFdzAEGBQEIRdGg/QVB+wvuKv/jG9ObiMNVkRXR2hez0ayBZigDgyFB3Bf/toaGxTCDWBfP/dhHLtQW6iJbjAtF6gsVR5bhOz8DolGvuUNlQAAAAABJRU5ErkJggg=='), auto;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-pixelated;
  image-rendering: pixelated;
  shape-rendering: crispEdges;
}

body {
  @apply font-sans;
}

a,
button:not(disabled),
label {
  cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC') 14 0, pointer;
}

button[disabled] {
  cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAbElEQVR4Ae3XgQaAQBAG4d7/pQuAYNYk9j//EdA2nxzuLrFueMTaA+AgLAAlAYbBMSgFwGHahDy3GPDpAwISAsAwzEUAfJh/NUPiAGKTpQN8+BSAD+cARi/+Gd4NeA3q8FpAT0Q9Ffdm1NvxA4SSqau88igLAAAAAElFTkSuQmCC'), auto;
}

.brand {
  @apply text-3xl font-medium text-indigo-500;
}

.skip-link {
  @apply absolute block overflow-hidden;

  z-index: 999;
  clip: rect(1px, 1px, 1px, 1px);
  height: theme('spacing.px');
  width: theme('spacing.px');

  &:focus {
    clip: auto;
    height: auto;
    overflow: visible;
    width: auto;
  }
}

.stretched-link::after {
  @apply absolute inset-0 z-10 bg-transparent pointer-events-auto;

  content: "";
}

.body-scroll-lock {
  touch-action: none;
  overflow: hidden;
}

.section-heading,
body .prose .section-heading {
  @apply text-3xl leading-8 tracking-wider text-center text-yellow-400 lowercase lg:text-4xl font-heading;
}

[id="hero-bg"] {
  background-image: url('/images/hero_bg.png');
  background-attachment: fixed;
  background-size: 430px;

  @screen lg {
    background-size: 860px;
  }
}

[id="wardrobe"] {
  background-color: theme('colors.blue.500');
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23000000' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
  box-shadow: inset 0px  2px 0px 0px theme('colors.teal.300'),
              inset 0px -2px 0px 0px theme('colors.blue.800');
}

[id="wardrobe-preview"] {
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23000000' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
  box-shadow: inset 0px  2px 0px 0px theme('colors.blue.200'),
              inset 0px -2px 0px 0px theme('colors.blue.900');
}

[id="wardrobe-preview-wrap"] {
  box-shadow: -1px 1px 0px 0px theme('colors.blue.200'),
              1px -1px 0px 0px theme('colors.blue.900');
}

[id="wardrobe-interface"] {
  box-shadow: -1px  1px 0px 1px theme('colors.teal.300'),
               1px -1px 0px 1px theme('colors.blue.800');
}

.tab-panel {
  box-shadow: -1px 1px 0px 0px theme('colors.teal.300'),
              1px -1px 0px 0px theme('colors.blue.800');
}

[id="main"] {
  background-color: theme('colors.teal.300');
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23659194' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
  background-attachment: fixed;
}

[id="faqs"] {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000000' fill-opacity='0.05' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
  box-shadow: inset 0px  2px 0px 0px theme('colors.teal.300'),
              inset 0px -2px 0px 0px theme('colors.blue.700');
}

[id="on-chain"] {
  background: theme('colors.blue.500') url('/images/bg_faq.png');
}

[id="footer"] {
  background-color: #05202f;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%230a2f43' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
}

// [id="staff-derp"] {
//   z-index: 10;
//   transform: translate(82px, 90px) rotate(-24deg);
// }

// [id="staff-rawdawg"] {
//   z-index: 20;
//   transform: translate(36px, 24px) rotate(-12deg);
// }

// [id="staff-sweetbread"] {
//   z-index: 30;
//   transform: translate(0px, 0px) rotate(0deg);
// }

// [id="staff-madotsuki"] {
//   z-index: 20;
//   transform: translate(-36px, 24px) rotate(12deg);
// }

// [id="staff-accell"] {
//   z-index: 10;
//   transform: translate(-82px, 90px) rotate(24deg);
// }

// [id="staff-derp"],
// [id="staff-rawdawg"],
// [id="staff-sweetbread"],
// [id="staff-madotsuki"],
// [id="staff-accell"] {
//   transition: transform 0.5s ease;
// }

// [id="staff-derp"] {
//   z-index: 10;
//   transform: translate(82px, -90px) rotate(24deg);
// }

// [id="staff-rawdawg"] {
//   z-index: 20;
//   transform: translate(36px, -24px) rotate(12deg);
// }

// [id="staff-sweetbread"] {
//   z-index: 30;
//   transform: translate(0px, 0px) rotate(0deg);
// }

// [id="staff-madotsuki"] {
//   z-index: 20;
//   transform: translate(-36px, -24px) rotate(-12deg);
// }

// [id="staff-accell"] {
//   z-index: 10;
//   transform: translate(-82px, -90px) rotate(-24deg);
// }

// .is-active {
//   & [id="staff-derp"] {
//     transform: translate(462px, 0px) rotate(0);
//   }

//   & [id="staff-rawdawg"] {
//     transform: translate(231px, 0px) rotate(0);
//   }

//   & [id="staff-sweetbread"] {
//     transform: translate(0px, 0px) rotate(0deg);
//   }

//   & [id="staff-madotsuki"] {
//     transform: translate(-231px, 0px) rotate(0);
//   }

//   & [id="staff-accell"] {
//     transform: translate(-462px, 0px) rotate(0);
//   }
// }

[id='WEB3_CONNECT_MODAL_ID'] {
  position: relative;
  z-index: 9999;
}

.mint-label {
  @apply absolute py-1 px-1.5 text-xs font-heading lowercase tracking-wide bg-red-500 border border-red-900 rounded text-white;

  bottom: -22px;
  right: -10px;
  transform: rotate(-4deg);

  @screen xs {
    right: -24px;
    transform: rotate(-5deg);
  }

  @screen md {
    top: -22px;
    right: -24px;
    bottom: auto;
    transform: rotate(5deg);
  }

  @screen lg {
    right: -71px;
  }
}
