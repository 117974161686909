.button {
  @apply inline-flex items-center justify-center px-4 py-2 border border-gray-500 text-sm uppercase font-normal whitespace-nowrap rounded text-gray-700 bg-gray-300 disabled:opacity-50;

  box-shadow: inset 0px 0px 0px 1px theme('colors.lighten.800');
}

.button--xs {
  @apply px-2.5 py-1.5 text-xs leading-none;
}

.button--sm {
  @apply px-3 py-2 text-xs leading-4;
}

.button--base {
  @apply px-4 py-2 text-sm;
}

.button--lg {
  @apply px-4 py-2 text-base;
}

.button--xl {
  @apply px-6 py-3 text-base;
}

.button--primary {
  @apply bg-blue-500 border border-blue-900 text-white active:bg-blue-600 hover:bg-blue-400 disabled:hover:bg-blue-500;
}

.button--secondary {
  @apply bg-teal-500 border border-teal-900 text-white active:bg-teal-600 hover:bg-teal-400 disabled:hover:bg-teal-500;
}

.button--tertiary {
  @apply bg-yellow-500 border border-yellow-900 text-white active:bg-yellow-600 hover:bg-yellow-400 disabled:hover:bg-yellow-500;
}

.button--error {
  @apply bg-red-500 border border-red-900 text-white active:bg-red-600 hover:bg-red-400 disabled:hover:bg-red-500;
}

.button--success {
  @apply bg-green-500 border border-green-900 text-white active:bg-green-600 hover:bg-green-400 disabled:hover:bg-green-500;
}

.button--warning {
  @apply bg-yellow-500 border border-yellow-900 text-white active:bg-yellow-600 hover:bg-yellow-400 disabled:hover:bg-yellow-500;
}

.button--gender {
  @apply bg-blue-500 border border-black text-white active:bg-blue-400 hover:bg-blue-400 disabled:hover:bg-blue-500;

  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23000000' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
  box-shadow: inset 0px 0px 0px 1px theme('colors.blue.300');

  &.button--active {
    @apply bg-blue-700 border border-blue-300;

    box-shadow: inset 0px 0px 0px 1px theme('colors.black');
  }
}
