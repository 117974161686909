/**
 * Config
 */
@import '_config/font-faces';
@import '_config/variables';

/**
 * Base
 */
@import 'tailwindcss/base';
@import 'base/global';
@import 'base/backdrop';
@import 'base/heading-label';
@import 'base/image-bevel';
@import 'base/input';
@import 'base/modal';
@import 'base/scrollbar';
@import 'base/tabs';
@import 'base/table';

/**
 * Components & Utils
 */
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  @variants responsive {
    @import 'utilities/button';
    @import 'utilities/shadow';
    @import 'utilities/text-shadow';

    .bg-inherit {
      background-color: inherit;
    }

    .grid-cols-17 {
      grid-template-columns: repeat(17, minmax(0, 1fr));
    }

    .grid-cols-18 {
      grid-template-columns: repeat(18, minmax(0, 1fr));
    }

    .grid-cols-19 {
      grid-template-columns: repeat(19, minmax(0, 1fr));
    }

    .grid-cols-20 {
      grid-template-columns: repeat(20, minmax(0, 1fr));
    }

    .grid-cols-21 {
      grid-template-columns: repeat(21, minmax(0, 1fr));
    }

    .grid-cols-22 {
      grid-template-columns: repeat(22, minmax(0, 1fr));
    }

    .grid-cols-23 {
      grid-template-columns: repeat(23, minmax(0, 1fr));
    }

    .grid-cols-24 {
      grid-template-columns: repeat(24, minmax(0, 1fr));
    }

    .grid-cols-25 {
      grid-template-columns: repeat(25, minmax(0, 1fr));
    }

    .text-justify-left {
      text-align: justify;
      text-align-last: left;
    }

    .text-justify-right {
      text-align: justify;
      text-align-last: right;
    }

    .text-justify-center {
      text-align: justify;
      text-align-last: center;
    }

    .text-shadow {
      text-shadow: 0 2px 4px rgba(0,0,0,0.10);
    }

    .text-shadow-md {
      text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08);
    }

    .text-shadow-lg {
      text-shadow: 0 15px 30px rgba(0,0,0,0.11), 0 5px 15px rgba(0,0,0,0.08);
    }

    .text-shadow-none {
      text-shadow: none;
    }
  }
}
